import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { CallToAction } from "../components/CallToAction";
import { Container } from "../components/Common";
import { Layout } from "../components/Layout";
import { BigTitle, TitleContainer } from "../components/TextComponents";
import { Banner } from "../components/Banner";
import { breakpoints } from "../styledResponsive";
import { theme } from "../theme";
import { Link } from "gatsby";

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  width: 100%;
`;

const HighlightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 64px;
  ${breakpoints("flex-direction", ["column", "column", "row", "row"])};
`;

const HighlightTitle = styled.h3`
  font-size: 32px;
  margin-bottom: 32px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
`;
const HighlightText = styled.p`
  font-size: 18px;
  margin-bottom: 32px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
`;
const Left = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;
const LeftText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const Right = styled.div`
  flex: 1;
  padding: 32px;
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 64px;
  margin-bottom: 64px;
  ${breakpoints("flex-direction", ["column", "column", "row", "row"])};
  gap: 32px;
`;

const ProductBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const ProductContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
`;

const ProductTitle = styled.h4`
  font-size: 48px;
  margin-bottom: 32px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
`;

const ProductSubtitle = styled.h5`
  font-size: 18px;
  color: #fff;
  font-weight: lighter;
  margin-top: -18px;
  margin-bottom: 18px;
  font-style: italic;
`;

const ProductButton = styled(Link)`
  background-color: ${(props) => props.theme.colors.brown};
  color: #fff;
  padding: 12px 32px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #fff;
    color: #000;
  }
`;

const ProductImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;

const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  background-color: #fff;
  padding-top: 64px;
  margin-bottom: 120px;
`;
const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 32px;
  height: 0;
  overflow: hidden;
  iframe,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Break = styled.br`
  display: none;
  ${breakpoints("display", ["none", "none", "block", "block"])};
`

const IndexPage = () => {
  return (
    <Layout>
      <>
        <Banner />
        <Wrapper>
          <Container>
            <HighlightContainer>
              <Left>
                <LeftText>
                  <HighlightTitle>
                    Uma marca focada
                    <br /> em qualidade.
                  </HighlightTitle>
                  <HighlightText>
                    Nossos azeites de oliva são de alto padrão e receberam o selo
                    de produto premium pelo IBRA OLIVA, com base em critérios de
                    alta qualidade dos produtos.
                  </HighlightText>
                </LeftText>
              </Left>
              <Right>
                <StaticImage
                  src="../images/1-home.jpg"
                  alt="IIBRA OLIVA"
                  style={{ width: "100%", minHeight: 300 }}
                />
              </Right>
            </HighlightContainer>
          </Container>
          <Container style={{ borderTop: "1px solid #000" }} id="produtos">
            <ProductsContainer>
              <ProductBox>
                <ProductImageContainer>
                  <StaticImage
                    src="../images/azeites.jpg"
                    alt="Azeites"
                    style={{ width: "100%" }}
                  />
                  <ProductContent>
                    <ProductTitle>Azeites</ProductTitle>
                    <Link to="/products/azeites"></Link>
                  </ProductContent>
                </ProductImageContainer>
                <ProductButton to="https://estanciadoforte.lojavirtualnuvem.com.br/">Ver mais</ProductButton>
              </ProductBox>
              <ProductBox>
                <ProductImageContainer>
                  <StaticImage
                    src="../images/nozes.jpg"
                    alt="Nozes"
                    style={{ width: "100%" }}
                  />
                  <ProductContent>
                    <ProductTitle style={{ marginBottom: 8 }}>Nozes</ProductTitle>
                    <ProductSubtitle>em breve</ProductSubtitle>
                    <Link to="/products/azeites"></Link>
                  </ProductContent>
                </ProductImageContainer>
                <ProductButton to="#" style={{ opacity: 0.5 }}>Ver mais</ProductButton>
              </ProductBox>
              <ProductBox>
                <ProductImageContainer>
                  <StaticImage
                    src="../images/vinhos.jpg"
                    alt="Vinhos"
                    style={{ width: "100%" }}
                  />
                  <ProductContent>
                    <ProductTitle style={{ marginBottom: 8 }}>
                      Vinhos
                    </ProductTitle>
                    <ProductSubtitle>em breve</ProductSubtitle>
                    <Link to="/products/azeites"></Link>
                  </ProductContent>
                </ProductImageContainer>
                <ProductButton to="#" style={{ opacity: 0.5 }}>
                  Ver mais
                </ProductButton>
              </ProductBox>
            </ProductsContainer>
          </Container>
        </Wrapper>
        <VideoWrapper>
          <BigTitle style={{marginBottom: 32}}>Conheça a Estância:</BigTitle>
          <VideoContainer>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/A2hVFeqyaLs"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              frameBorder="0"
            ></iframe>
          </VideoContainer>
        </VideoWrapper>
      </>
    </Layout>
  );
};

export default IndexPage;
