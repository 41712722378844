import React from "react";
import styled from "styled-components";
import { breakpoints } from "../styledResponsive";

export const BigTitle = styled.div`
  font-size: 32px;
  margin-bottom: 32px;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;
`;

export const TitleContainer = styled.div`
  margin-bottom: 36px;
`;
