import styled from "styled-components";
import { breakpoints } from "../styledResponsive";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${breakpoints("width", ["100%", "100%", "960px", "960px"])};
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
`;
