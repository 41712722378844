import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import "./carousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { breakpoints } from "../styledResponsive";
import { Link } from "gatsby";
import { Break } from "../pages";

const Container = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  position: relative;
`;

const Slide = styled.div`
  width: 100%;
  position: relative;
  min-height: 400px;
`;

const ImageContainer = styled.div`
  width: 219px;
  ${breakpoints("width", ["219px", "350px", "250px", "350px"])};
`;

const MobileSliderContainer = styled.div`
  ${breakpoints("display", ["block", "block", "none", "none"])};
`;

const DesktopSliderContainer = styled.div`
  display: block;
  position: relative;
  /* display: none; */
  /* ${breakpoints("display", ["none", "none", "block", "block"])}; */
`;

export const Anchor = styled.a`
  width: 40px;
  ${breakpoints("width", ["40px", "40px", "40px", "50px"])};
  display: block;
  margin-top: 80px;
`;

const imageStyles = {
  maxWidth: "100%",
  display: "block",
  minHeight: 400,
};

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 32px;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
  ${breakpoints("align-items", ["center", "center", "flex-start", "flex-start"])};
`
export const BannerButton = styled(Link)`
  background-color: #fff;
  color: #000;
  padding: 16px 32px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  margin-top: 32px;
  transition: all 0.3s ease;
  &:hover {
    background-color: #000;
    color: #fff;
  }
`

export const BannerTitle = styled.h1`
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 32px;
  text-align: center;
  ${breakpoints("font-size", ["32px", "32px", "32px", "48px"])};
  ${breakpoints("text-align", ["center", "center", "left", "left"])};
`

export const Banner: React.FC = () => {
  return (
    <Container>
      <DesktopSliderContainer>
        {/* <Carousel showThumbs={false} autoPlay={false}>
          <Slide>
            <StaticImage
              style={imageStyles}
              src="../images/banners/7-desktop.jpg"
              alt="Terroir"
            />
          </Slide>
        </Carousel> */}
            <ContentContainer>
              <BannerTitle>Produtos de qualidade direto<Break /> da campanha gaúcha</BannerTitle>
              <BannerButton style={{marginRight: -4}} to="/#produtos">Conheça nossos produtos</BannerButton>
            </ContentContainer>
            <StaticImage
              style={imageStyles}
              src="../images/banners/banner-home-1.jpg"
              alt="Estancia do Forte"
            />
      </DesktopSliderContainer>
      {/* <MobileSliderContainer>
        <Carousel showThumbs={false} autoPlay={false}>
          <Slide>
            <StaticImage
              style={imageStyles}
              src="../images/banners/1-mobile.jpg"
              alt="Terroir"
            />
          </Slide>
          <Slide>
            <StaticImage
              style={imageStyles}
              src="../images/banners/2-mobile.jpg"
              alt="Terroir"
            />
          </Slide>
          <Slide>
            <StaticImage
              style={imageStyles}
              src="../images/banners/3-mobile.jpg"
              alt="Terroir"
            />
          </Slide>
        </Carousel>
      </MobileSliderContainer> */}
    </Container>
  );
};
